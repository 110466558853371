//APP MODULES
import { AuthModule } from '@app/modules/auth/auth.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

// COMPONENTS & LIBS'
import { AppComponent } from './app.component';
import {
  AppSettingsEffects,
  AuthEffects,
  DevicesEffects,
  LocationsEffects,
  ClientUsersEffects,
  reducers,
  IncidentsEffects,
  ClientsManagementsEffects,
} from '@ngrx-store';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { UserActivityModalComponent } from './components/user-activity-modal/user-activity-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { TippyDirective, popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from '@pipes';
import { DirectivesModule } from '@directives';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { InstanceBadgeComponent } from '@app/modules/main/components/instance-badge/instance-badge.component';
import { CopyrightFooterComponent } from '@standalone/copyright-footer/copyright-footer.component';
import { AppInterceptor } from '@app-lib';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { metaReducers } from '@ngrx-store';
import { RouterHistoryService } from '@services';
import { Observable } from 'rxjs';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export function initializeRouterHistory(routerHistoryService: RouterHistoryService): () => Observable<void> {
  return () => routerHistoryService.init();
}

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

export const HttpLoaderFactory = (httpClient: HttpClient) => {
  return new TranslateHttpLoader(httpClient);
};

// test
@NgModule({
  declarations: [AppComponent, MainMenuComponent, UserActivityModalComponent, InstanceBadgeComponent],
  bootstrap: [AppComponent],
  exports: [CopyrightFooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AuthModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
    EffectsModule.forRoot([
      LocationsEffects,
      AuthEffects,
      AppSettingsEffects,
      ClientUsersEffects,
      DevicesEffects,
      IncidentsEffects,
      ClientsManagementsEffects,
    ]),
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    DashboardModule,
    MatTooltipModule,
    MatDialogModule,
    ToastrModule.forRoot({
      enableHtml: true,
      positionClass: 'toast-bottom-center',
      disableTimeOut: false,
      timeOut: 3000,
      preventDuplicates: true,
    }),
    MatSelectModule,
    FormsModule,
    TippyDirective,
    PipesModule,
    DirectivesModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'loading...' }),
    NgxMaskDirective,
    NgxMaskPipe,
    NgIdleKeepaliveModule,
    CopyrightFooterComponent,
    SearchInputComponent,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    RouterHistoryService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeRouterHistory,
      deps: [RouterHistoryService],
      multi: true,
    },
    provideEnvironmentNgxMask(),
    HttpClient,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    Keepalive,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
