<div class="pt-4" *ngIf="locationFullData">
  <mat-card-title class="card-title mb-6">Add & Assign Devices</mat-card-title>

  <div class="flex mb-5">
    <label class="input-main-label">Floor:</label>
    <mat-form-field class="select-classic">
      <mat-select
        [value]="selectedFloorId"
        (valueChange)="changeFloor($event)"
        placeholder="Select floor"
        panelClass="single-select-overlay">
        <mat-option *ngFor="let floor of floorOptions | sort : 'asc' : 'title'" [value]="floor.value">
          {{ floor.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>
  </div>

  <div class="flex mb-6">
    <label class="input-main-label">Room:</label>
    <mat-form-field class="select-classic">
      <mat-select
        [value]="selectedRoomId"
        (valueChange)="changeRoom($event)"
        placeholder="Select room"
        panelClass="single-select-overlay">
        <mat-option *ngFor="let room of roomOptions | sort : 'asc' : 'title'" [value]="room.value">
          {{ room.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="selectedRoom">
    @let devices = (deviceList$ | async);
    <hr class="mb-4 border-ui-gray-light" />
    <app-spaceplan-tabs
      *ngIf="devices"
      [locationId]="locationFullData.id"
      [room]="selectedRoom"
      [popover]="editDevicePopover"
      (tabSelect)="selectedDocumentTab = $event"
      [canEdit]="true" />

    <div class="mt-3 mb-2">
      <mat-button-toggle-group [value]="deviceTab" (change)="tabChange($event)" class="main-toggle-group">
        <mat-button-toggle class="border-ui-green-light" value="list">
          <mat-icon matIconPrefix *ngIf="deviceTab === 'list'">check</mat-icon>
          Device List
        </mat-button-toggle>
        <mat-button-toggle class="border-ui-green-light" value="add">
          <mat-icon matIconPrefix *ngIf="deviceTab === 'add'">check</mat-icon>
          {{ deviceToEdit ? 'Edit' : 'Add' }} Device
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <app-device-list
      *ngIf="deviceTab === 'list'"
      [documentTab]="selectedDocumentTab"
      [roomId]="selectedRoom.id"
      [locationId]="locationFullData.id"
      [roomplan]="selectedRoom.documentReference"
      [showHeader]="false"
      (editDevice)="editDevice($event)" />

    <div *ngIf="deviceTab === 'add'">
      <app-new-device-form
        [device]="deviceToEdit"
        [showHeader]="false"
        [showBreadcrumbs]="false"
        [cardTitle]="deviceToEdit ? 'Edit' : 'Add' + ' Device'"
        [deviceLocation]="{ siteId: locationFullData.id, floorId: selectedFloorId, roomId: selectedRoom.id }" />
    </div>
  </div>
</div>
