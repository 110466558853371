<app-page-header><div class="leading-10">Reporting</div></app-page-header>

<div class="flex items-center justify-between mt-4">
  <mat-button-toggle-group [(ngModel)]="reportingType" class="main-toggle-group" aria-label="type of reports">
    <mat-button-toggle class="border-ui-green-light" value="incident">
      <mat-icon matIconPrefix *ngIf="reportingType === 'incident'">check</mat-icon>
      Incident
    </mat-button-toggle>
    <mat-button-toggle class="border-ui-green-light" value="firmware">
      <mat-icon matIconPrefix *ngIf="reportingType === 'firmware'">check</mat-icon>
      Firmware
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-export-report-menu
    *ngIf="reportingType === 'firmware'"
    [disabled]="!devicesFilterService.filteredDevices.length"
    (exportDataType)="exportDevices($event)" />
  <app-export-report-menu
    *ngIf="reportingType === 'incident'"
    [disabled]="!incidents?.length"
    (exportDataType)="exportIncidents($event)" />
</div>

<div [hidden]="reportingType !== 'incident'" class="test-incident-report-filters">
  <mat-card class="bg-white card mt-6">
    <mat-card-content class="p-0">
      <app-report-filters
        [reportingType]="reportingType"
        (reportTypeEvent)="reportingType = $event"
        (exportIncidents)="setIncidents($event)" />
    </mat-card-content>
  </mat-card>
</div>

<div [hidden]="!(reportingType === 'incident' && incidents)" class="test-incident-table">
  @if (incidents?.length){
  <app-incident-charts [incidents]="incidents" />
  } @else {
  <div class="card-separator"></div>
  }
  <mat-card class="card py-0 mb-3">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5 mb-3"
      >Incident List
    </mat-card-title>
    <mat-card-content class="p-0 pb-2">
      <app-incidents-table
        tableId="reporting-incidents-table"
        [incidentsData]="incidents || []"
        [isReportMode]="true"
        [isInjected]="true" />
    </mat-card-content>
  </mat-card>
</div>

<div [ngClass]="{ 'is-hidden-tab': reportingType !== 'firmware' }" class="test-firmware-table">
  <mat-card class="card pt-0 mt-6 pb-2">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5"
      >Firmware List
    </mat-card-title>
    @let deviceListLoaded = deviceListLoaded$ | async ; @if (deviceListLoaded) {
    <mat-card-content class="p-0">
      <app-devices-table-desktop-filters tableId="reporting-device-table" [showColumnSelect]="false" />
      <app-devices-table tableId="reporting-device-table" [isInjected]="true" />
    </mat-card-content>
    } @else {
    <app-skeleton-table />
    }
  </mat-card>
</div>

<div [hidden]="reportingType !== 'incident'" class="test-default-charts pb-4">
  <div class="grid grid-cols-2 gap-4 my-4 mx-auto">
    <app-incident-manufacturer-chart />
    <app-incidents-monthly-chart />
  </div>
</div>
