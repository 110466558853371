<h1 class="dialog-title">
  <span>Add new model</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>

<mat-dialog-content class="dialog-content">
  <form [formGroup]="form" id="model-form" (ngSubmit)="createModel()">
    <div class="flex flex-row">
      <label for="image" class="input-main-label">Model image:</label>
      <div>
        <div class="classic-form-item-wrapper">
          <div
            *ngIf="!imagePreviewSrc && !file"
            class="w-[270px] h-[200px] flex justify-center items-center bg-ui-gray-50 text-ui-gray-100 relative border-dashed border-2 border-ui-gray-light mb-14">
            <div class="flex flex-col items-center justify-center">
              <i class="icon-upload-map text-5xl mb-1.5"></i>
              <span class="font-nunito font-semibold text-xs">Upload model image</span>
            </div>
            <input
              class="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
              type="file"
              type="file"
              id="image"
              (change)="onFileChange($event)"
              accept="image/*" />
          </div>
          <div *ngIf="imagePreviewSrc && file" class="w-[270px] flex flex-col mb-4">
            <img [src]="imagePreviewSrc" alt="preview" class="mb-4" />
            <div class="text-ui-gray-100 font-nunito text-sm font-semibold flex items-center">
              <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{ file.name }}</span>
              <mat-icon class="test-button cursor-pointer ml-1" (click)="deleteFile()">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <label for="name" class="input-main-label">Model name:*</label>
      <div>
        <mat-form-field class="input-classic">
          <input matInput id="name" type="text" placeholder="Enter model name" formControlName="name" />
          <mat-error *ngIf="form.get('name')?.hasError('required')">Input is not valid</mat-error>
          <mat-error *ngIf="form.get('name')?.hasError('alreadyExist')">Value already exist</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Device type:*</label>
      <div>
        <mat-form-field class="select-classic">
          <mat-select formControlName="deviceType" placeholder="Select device type" panelClass="single-select-overlay">
            <button class="btn-small btn-green mb-4 ml-3" (click)="openAddDeviceTypeDialog()">
              <mat-icon>add</mat-icon>
              Add new device type
            </button>
            <div class="mb-1 mx-3">
              <app-search-input
                placeholder="Search device type"
                inputWidth="100%"
                (searchDataEvent)="deviceTypeSearch($event)"></app-search-input>
            </div>
            <mat-option
              *ngFor="let deviceType of deviceTypeOptions | filterSelectOptions : deviceTypeFilterValue"
              [value]="deviceType.value"
              class="full-width-option">
              <div class="flex justify-between items-center">
                {{ deviceType.title }}
                <i
                  class="icon-button icon-edit text-3xl cursor-pointer text-ui-green-100 mx-2"
                  (click)="editDeviceType($event)"></i>
              </div>
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Manufacturer:*</label>
      <div>
        <mat-form-field class="select-classic">
          <mat-select
            formControlName="manufacturer"
            placeholder="Select manufacturer"
            panelClass="single-select-overlay">
            <button class="btn-small btn-green mb-4 ml-3" (click)="openAddManufacturerDialog()">
              <mat-icon>add</mat-icon>
              Add new manufacturer
            </button>
            <div class="mb-1 mx-3">
              <app-search-input
                placeholder="Search manufacturer"
                inputWidth="100%"
                (searchDataEvent)="manufacturerSearch($event)"></app-search-input>
            </div>
            <mat-option
              *ngFor="let manufacturer of manufacturerOptions | filterSelectOptions : manufacturerFilterValue"
              [value]="manufacturer.value"
              class="full-width-option">
              <div class="flex justify-between items-center">
                {{ manufacturer.title }}
                <i
                  class="icon-button icon-edit text-3xl cursor-pointer text-ui-green-100 mx-2"
                  (click)="editManufacturer($event)"></i>
              </div>
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <mat-card-title class="card-title-small mb-5">Select existing data points or add new ones</mat-card-title>

    <div class="flex flex-row test-realtime-attribute-controls mb-5" *ngIf="attributes.length">
      <label class="input-main-label mt-0">Select existing data points:</label>
      <div>
        <ul class="classic-form-item-wrapper flex flex-wrap">
          <li *ngFor="let attribute of attributes; let i = index" class="w-1/2">
            <mat-checkbox
              class="main-checkbox"
              form="model-form"
              [value]="attribute.id"
              (change)="realtimeAttributeChange($event, i)"
              >{{ attribute.name }}
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </form>
  @let isNewAttributeCreating = (isNewAttributeCreating$ | async) || false;
  <app-new-attribute-form
    [realtimeAttributes]="attributes"
    (newAttributeEvent)="createAttribute($event)"
    [isNewAttributeCreating]="isNewAttributeCreating" />
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
  <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium mr-4 font-bold">CANCEL</button>
  <button
    type="submit"
    form="model-form"
    class="test-submit-btn btn-green btn-medium font-bold"
    [disabled]="(isDeviceModelCreating$ | async) === true || (isDeviceModelImageUploading$ | async) === true">
    <mat-spinner
      *ngIf="(isDeviceModelCreating$ | async) === true || (isDeviceModelImageUploading$ | async) === true"
      diameter="20"
      class="mr-1"></mat-spinner>
    SAVE
  </button>
</mat-dialog-actions>
