import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { ManufacturerListComponent } from '../../components/manufacturer-list/manufacturer-list.component';
import { DataPointsComponent } from '../../components/data-points/data-points.component';
import { ModelListComponent } from '../../components/model-list/model-list.component';
import { MatPrefix } from '@angular/material/form-field';

enum DeviceTabs {
  MODEL = 'model',
  DATA_POINTS = 'dataPoints',
  MANUFACTURER = 'manufacturer',
}

@Component({
  selector: 'app-device-management',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatIcon,
    RouterModule,
    MatButtonToggleModule,
    FormsModule,
    ManufacturerListComponent,
    DataPointsComponent,
    ModelListComponent,
    MatPrefix,
  ],
  templateUrl: './device-management.component.html',
})
export class DeviceManagementComponent {
  tab: DeviceTabs = DeviceTabs.MANUFACTURER;
  deviceTabs = DeviceTabs;
}
