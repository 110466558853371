import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { dataPointOptionsMock } from '@mocks';
import { MatDialog } from '@angular/material/dialog';
import { removeSpacesValidator } from '@app-lib';
import {
  DeviceAttributeType,
  DeviceAdditionalAttribute,
  DeviceAttribute,
  DeviceData,
  DeviceFullModel,
  DeviceLocation,
  DeviceModel,
  SelectOption,
  Space,
  SpaceType,
  UserClient,
} from '@models';
import { Store } from '@ngrx/store';
import {
  AppState,
  DevicesActions,
  addNewDevice,
  clientList,
  getAttributes,
  getDeviceManufacturers,
  getDeviceModels,
  getFullModel,
  isDeviceCreating,
  isDeviceUpdating,
  loadDeviceFullModel,
  loadDeviceFullModelSuccess,
  updateDeviceData,
  getMyClient,
} from '@ngrx-store';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService, DeviceHelperService, LocationService } from '@services';
import { Actions, ofType } from '@ngrx/effects';
import { openAddManufacturerDialog } from '@standalone/_modals/add-manufacturer-dialog/add-manufacturer-dialog.component';
import { openAddDeviceTypeDialog } from '@standalone/_modals/add-device-type-dialog/add-device-type-dialog.component';
import { openAddDeviceModelDialog } from '@standalone/_modals/add-device-model-dialog/add-device-model-dialog.component';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { AsyncPipe, CommonModule, NgForOf, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { PipesModule } from '@pipes';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { NgxMaskDirective } from 'ngx-mask';
import { MatCheckbox } from '@angular/material/checkbox';
import { DirectivesModule } from '@directives';
import { NewAttributeFormComponent } from '@standalone/new-attribute-form/new-attribute-form.component';

interface ExtendedDataPoint extends Pick<DeviceAttribute, 'name' | 'friendlyName' | 'attributeType'> {
  isChecked?: boolean;
}

@Component({
  standalone: true,
  selector: 'app-new-device-form',
  templateUrl: './new-device-form.component.html',
  imports: [
    CommonModule,
    PageHeaderComponent,
    BreadcrumbsComponent,
    AsyncPipe,
    MatProgressSpinner,
    MatSuffix,
    MatError,
    NgIf,
    MatCard,
    ReactiveFormsModule,
    MatCardTitle,
    MatCardContent,
    MatFormField,
    MatInput,
    MatSelect,
    MatIcon,
    SearchInputComponent,
    MatOption,
    NgForOf,
    PipesModule,
    ImgLoaderComponent,
    NgxMaskDirective,
    MatCheckbox,
    DirectivesModule,
  ],
})
export class NewDeviceFormComponent implements OnInit, OnDestroy {
  @ViewChild(NewAttributeFormComponent) newAttributeFormComponent: NewAttributeFormComponent | undefined;
  @Input() showBreadcrumbs = true;
  @Input() deviceLocation: DeviceLocation | null = null;
  @Input() device: DeviceData | null = null;
  @Input() showHeader = true;
  @Input() cardTitle = 'Device Info';
  @Output() createDeviceCallback = new EventEmitter();
  destroyRef = inject(DestroyRef);

  clients$: Observable<UserClient[] | undefined>;
  myClient$: Observable<UserClient | undefined>;
  isDeviceCreating$: Observable<boolean>;
  isDeviceUpdating$: Observable<boolean>;
  form: FormGroup;
  buildingOptions: SelectOption[] = [];
  floorOptions: SelectOption[] = [];
  roomOptions: SelectOption[] = [];
  dataPointsOptions = dataPointOptionsMock;
  deviceTypeOptions: SelectOption[] = [];
  manufacturerOptions: SelectOption[] = [];
  modelOptions: SelectOption[] = [];
  manufacturerFilterValue = '';
  modelFilterValue = '';
  deviceModels: DeviceModel[] = [];
  fakeSelectOptions: SelectOption[] = [{ value: '1', title: '1' }];
  spaceList: Space[] = [];
  isSpaceListLoading = false;
  realtimeAttributes: DeviceAttribute[] = [];
  selectedFullModel: DeviceFullModel | null = null;

  areInitializedDeviceDynamicControls = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private locationService: LocationService,
    public appService: AppService,
    public deviceHelperService: DeviceHelperService,
    actions$: Actions
  ) {
    this.clients$ = this.store.select(clientList);
    this.myClient$ = this.store.select(getMyClient);
    this.isDeviceCreating$ = this.store.select(isDeviceCreating);
    this.isDeviceUpdating$ = this.store.select(isDeviceUpdating);
    this.deviceHelperService.deviceTypeOptions$
      .pipe(takeUntilDestroyed())
      .subscribe(deviceTypeOptions => (this.deviceTypeOptions = deviceTypeOptions));

    this.form = fb.group({
      deviceName: ['', [Validators.required, removeSpacesValidator]],
      deviceType: ['', [Validators.required]],
      manufacturer: ['', [Validators.required]],
      model: [{ value: '', disabled: true }, [Validators.required]],
      physicalDeviceId: ['', [Validators.required, removeSpacesValidator]],
      tags: this.fb.group({
        firmware: [''],
        description: [''],
        ipAddress: [''],
        macAddress: [''],
        hyperlink: [{ value: '', disabled: true }],
      }),
      building: ['', [Validators.required]],
      floor: [{ value: '', disabled: true }],
      room: [{ value: '', disabled: true }],
    });

    this.store
      .select(getDeviceManufacturers)
      .pipe(takeUntilDestroyed())
      .subscribe(manufacturers => {
        if (manufacturers.length) {
          this.manufacturerOptions = manufacturers.map(({ id, name }) => ({ title: name, value: id }));
        }
      });

    this.store
      .select(getDeviceModels)
      .pipe(takeUntilDestroyed())
      .subscribe(models => {
        if (models.length) {
          this.deviceModels = models;
          this.deviceHelperService.initDeviceTypeOptions(models);
          this.setModelSelectSettings(this.form.value.deviceType, this.form.value.manufacturer);
        }
      });

    this.store
      .select(getAttributes)
      .pipe(takeUntilDestroyed())
      .subscribe(attributes => {
        if (attributes.length) {
          this.realtimeAttributes = attributes.filter(
            attribute => attribute.attributeType === DeviceAttributeType.REALTIME
          );
          this.initDynamicControls();
        }
      });

    this.locationService
      .getLocationsList(this.appService.currentClient, false)
      .pipe(takeUntilDestroyed())
      .subscribe(locations => {
        this.buildingOptions = locations.map(({ id, friendlyName }) => ({ value: id, title: friendlyName }));
      });

    this.store
      .select(getFullModel)
      .pipe(takeUntilDestroyed())
      .subscribe(fullModel => {
        if (fullModel) {
          console.log('fullModel', fullModel);
          this.selectedFullModel = fullModel;
          this.initDynamicControls();
        }
      });

    actions$.pipe(ofType(DevicesActions.addNewDeviceSuccess), takeUntilDestroyed()).subscribe(() => {
      this.createDeviceCallback.emit();
      this.resetModelControls();
      this.form.reset();
      this.form.markAsPristine();

      if (this.deviceLocation) {
        const { siteId, floorId, roomId } = this.deviceLocation;
        this.form.get('building')?.setValue(siteId);
        this.form.get('floor')?.setValue(floorId);
        this.form.get('room')?.setValue(roomId);
      }
    });

    actions$.pipe(ofType(DevicesActions.deviceDataSuccessfullyUpdated), takeUntilDestroyed()).subscribe(() => {
      this.form.markAsUntouched();
    });
  }

  ngOnInit(): void {
    const building = this.form.get('building');
    const floor = this.form.get('floor');
    const room = this.form.get('room');
    const deviceType = this.form.get('deviceType');
    const manufacturer = this.form.get('manufacturer');
    const model = this.form.get('model');
    const physicalDeviceId = this.form.get('physicalDeviceId');
    const ipAddress = this.form.get('tags.ipAddress');
    const hyperlink = this.form.get('tags.hyperlink');

    if (this.deviceLocation) {
      const { siteId, floorId, roomId } = this.deviceLocation;

      building?.setValue(siteId);
      this.loadSpaces(siteId);
      building?.disable();
      floor?.setValue(floorId);
      floor?.disable();
      room?.setValue(roomId);
      room?.disable();
    }

    ipAddress?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value && hyperlink?.disabled) {
        hyperlink.enable();
      } else if (!value && !hyperlink?.disabled) {
        hyperlink?.disable();
      }
    });

    building?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (!value) return;
      this.loadSpaces(value);

      if (building.touched) {
        floor?.reset();
        room?.reset();
        room?.disable();
      }
    });

    floor?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (!value) return;
      this.generateRoomSelectOptions(value);
      if (!floor.pristine) {
        this.roomOptions.length ? room?.enable() : room?.disable();
        room?.reset();
      }
    });

    deviceType?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(deviceTypeValue => {
      this.resetModelControls();
      this.setModelSelectSettings(deviceTypeValue, this.form.value.manufacturer);
    });

    manufacturer?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(manufacturerValue => {
      this.resetModelControls();
      this.setModelSelectSettings(this.form.value.deviceType, manufacturerValue);
    });

    model?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedModelId => {
      if (selectedModelId) {
        this.resetModelControls();
        this.store.dispatch(
          loadDeviceFullModel({
            clientId: this.appService.currentClient,
            modelId: selectedModelId,
            manufacturerId: manufacturer?.value,
          })
        );
      }
    });

    if (this.device) {
      const floorId = this.device.spacePath?.find(space => space.type === SpaceType.floor)?.id || '';
      const roomId = this.device.spacePath?.find(space => space.type === SpaceType.room)?.id || '';
      const firmware =
        this.device.tags?.['firmware'] ||
        this.device.additionalAttributes?.find(item => item.name === 'firmware')?.value ||
        '';
      const macAddress =
        this.device.tags?.['macAddress'] ||
        this.device.additionalAttributes?.find(item => item.name === 'macAddress')?.value ||
        '';
      const ipAddress =
        this.device.tags?.['ipAddress'] ||
        this.device.additionalAttributes?.find(item => item.name === 'ipAddress')?.value ||
        '';
      physicalDeviceId?.disable();
      building?.disable();
      if (floorId) floor?.enable();
      if (roomId) room?.enable();

      this.form.setValue({
        deviceName: this.device.friendlyName,
        deviceType: this.device.deviceModelInformation?.deviceType,
        manufacturer: this.device.deviceModelInformation?.make.id,
        model: this.device.deviceModelInformation?.id,
        physicalDeviceId: this.device.physicalDeviceId,
        building: this.device.locationId || this.device.location.id,
        floor: floorId,
        room: roomId,
        tags: {
          firmware: firmware,
          ipAddress: ipAddress,
          macAddress: macAddress,
          hyperlink: this.device.tags?.['hyperlink'] || '',
          description: this.device.tags?.['description'] || '',
        },
      });
    }
  }

  initDynamicControls() {
    if (this.selectedFullModel && this.realtimeAttributes.length) {
      const modelRealtimeAttributes = this.selectedFullModel.standardAttributes.filter(
        attribute => attribute.attributeType === DeviceAttributeType.REALTIME
      );
      const deviceRealtimeAttributes = this.device?.additionalAttributes
        ? this.device.additionalAttributes!.filter(
            attribute => attribute.attributeType === DeviceAttributeType.REALTIME
          )
        : [];
      const initAttributes = (this.device ? deviceRealtimeAttributes : modelRealtimeAttributes).map(attr => ({
        ...attr,
        checked: true,
      }));
      const combinedAttributes = this.mergeAttributes(
        this.realtimeAttributes,
        initAttributes,
        this.getCurrentDataPointsFormState()
      );

      this.updateDataPointsForm(combinedAttributes);

      this.areInitializedDeviceDynamicControls = true;
    }
  }

  mergeAttributes(
    external: ExtendedDataPoint[],
    device: ExtendedDataPoint[],
    currentFormState: ExtendedDataPoint[] = []
  ): ExtendedDataPoint[] {
    const map = new Map<string, ExtendedDataPoint>();

    external.forEach(attr => {
      map.set(attr.name, { ...attr, isChecked: false });
    });

    device.forEach(attr => {
      if (map.has(attr.name)) {
        map.get(attr.name)!.isChecked = true;
      } else {
        map.set(attr.name, { ...attr, isChecked: true });
      }
    });

    currentFormState.forEach(attr => {
      if (map.has(attr.name)) {
        map.get(attr.name)!.isChecked = attr.isChecked;
      }
    });

    return Array.from(map.values());
  }

  getCurrentDataPointsFormState(): ExtendedDataPoint[] {
    return (
      this.form?.get('dataPoints')?.value.map((attr: ExtendedDataPoint) => ({
        name: attr.name,
        isChecked: attr.isChecked,
      })) || []
    );
  }

  updateDataPointsForm(combinedAttributes: ExtendedDataPoint[]) {
    this.form.setControl('dataPoints', this.fb.array([]));

    const attributesArray = this.form.get('dataPoints') as FormArray;
    combinedAttributes.forEach((attr, index) => {
      if (attributesArray?.at(index)) {
        attributesArray.at(index).patchValue(attr);
      } else {
        attributesArray.push(
          this.fb.group({
            name: [attr.name],
            friendlyName: [attr.friendlyName],
            attributeType: [attr.attributeType],
            isChecked: [attr.isChecked],
          })
        );
      }
    });

    while (attributesArray.length > combinedAttributes.length) {
      attributesArray.removeAt(attributesArray.length - 1);
    }
  }

  get dataPointsFormArray() {
    return this.form.get('dataPoints') as FormArray;
  }

  loadSpaces(locationId: string) {
    const floor = this.form.get('floor');
    this.isSpaceListLoading = true;
    floor?.disable();

    this.locationService
      .getSpacesList(this.appService.currentClient, locationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(spaces => {
        this.isSpaceListLoading = false;
        this.spaceList = spaces;
        this.generateFloorSelectOptions();
        if (this.floorOptions.length && !this.deviceLocation) {
          floor?.enable();
        }

        if (this.deviceLocation) {
          this.generateRoomSelectOptions(this.deviceLocation.floorId);
        }
      });
  }

  generateFloorSelectOptions() {
    this.floorOptions = this.spaceList
      .filter(({ type }) => type === SpaceType.floor)
      .map(({ id, friendlyName, name }) => ({ value: id, title: friendlyName || name }));
  }

  generateRoomSelectOptions(floorId: string) {
    this.roomOptions = this.spaceList
      .filter(({ parentSpaceId }) => parentSpaceId === floorId)
      .map(({ id, friendlyName, name }) => ({ value: id, title: friendlyName || name }));
  }

  setModelSelectSettings(deviceTypeValue: string, manufacturerValue: string) {
    if (manufacturerValue && deviceTypeValue) {
      const model = this.form.get('model');
      const deviceType = this.form.get('deviceType');
      const manufacturer = this.form.get('manufacturer');
      if (model?.touched || deviceType?.dirty || manufacturer?.dirty) {
        model?.setValue('');
      }
      model?.enable();
      this.modelOptions = this.deviceModels
        .filter(deviceModel => deviceModel.make.id === manufacturerValue && deviceModel.deviceType === deviceTypeValue)
        .map(deviceModel => ({ value: deviceModel.id, title: deviceModel.name }));
    }
  }

  ngOnDestroy() {
    this.store.dispatch(loadDeviceFullModelSuccess({ fullModel: null }));
    this.deviceHelperService.resetState();
  }

  setModelOptions() {
    const manufacturerValue = this.form.value.manufacturer;
    const deviceTypeValue = this.form.value.deviceType;
    if (manufacturerValue && deviceTypeValue) {
      this.form.get('model')?.enable();
      this.modelOptions = this.deviceModels
        .filter(deviceModel => deviceModel.make.id === manufacturerValue && deviceModel.deviceType === deviceTypeValue)
        .map(deviceModel => ({ value: deviceModel.id, title: deviceModel.name }));
    }
  }

  resetModelControls() {
    if (this.form.get('model')?.touched) {
      this.selectedFullModel = null;
      this.form.removeControl('dataPoints');
    }
  }

  get dataPointlabelDetails() {
    const selectedValues = this.form.controls['dataPoint']?.value || [];
    return selectedValues?.length
      ? `${this.dataPointsOptions.find(option => option.value === selectedValues[0])?.title} ${
          selectedValues.length > 1 ? `(+${selectedValues.length - 1})` : ''
        }`
      : '';
  }

  submit() {
    if (!this.form.touched) {
      return;
    }

    const { tags, room, floor, physicalDeviceId, deviceName, model, dataPoints, building } = this.form.getRawValue();
    const additionalAttributes: Array<DeviceAdditionalAttribute> = (dataPoints as ExtendedDataPoint[])
      .filter(attr => attr.isChecked)
      .map(attr => ({
        name: attr.name,
        friendlyName: attr.friendlyName,
        attributeType: attr.attributeType,
        recordedTimeStamp: null,
        value: null,
      }));

    const newDeviceData = {
      tags,
      parentSpaceId: room || floor,
      physicalDeviceId,
      friendlyName: deviceName,
      deviceModelId: model,
      additionalAttributes,
    };

    if (this.device) {
      this.store.dispatch(
        updateDeviceData({
          locationId: this.device.locationId || this.device.location.id,
          deviceId: this.device.id,
          data: newDeviceData,
        })
      );
    } else {
      this.store.dispatch(addNewDevice({ locationId: building, newDeviceData }));
    }
  }

  openAddDeviceTypeDialog() {
    openAddDeviceTypeDialog(this.dialog);
  }

  openAddManufacturerDialog() {
    openAddManufacturerDialog(this.dialog);
  }

  openAddDeviceModelDialog() {
    openAddDeviceModelDialog(this.dialog, {
      selectedDeviceType: this.form.value.deviceType,
      selectedManufacturer: this.form.value.manufacturer,
    });
  }

  manufacturerSearch(value: string) {
    this.manufacturerFilterValue = value;
  }

  modelSearch(value: string) {
    this.modelFilterValue = value;
  }

  editManufacturer(event: Event) {
    // prevents option selection
    event.stopPropagation();
  }

  realtimeAttributeChange(dataPoint: ExtendedDataPoint, i: number) {
    if (!dataPoint.isChecked && this.device?.additionalAttributes?.some(({ name }) => name === dataPoint.name)) {
      ConfirmationDialogComponent.open(this.dialog, {
        title: `Remove ${dataPoint.name}`,
        description: `Do you really want to remove ${dataPoint.name}?`,
      })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirmation => {
          if (this.realtimeAttributes.some(attr => attr.name === dataPoint.name)) {
            this.dataPointsFormArray.at(i).patchValue({ ...dataPoint, isChecked: !confirmation });
          } else {
            this.dataPointsFormArray.removeAt(i);
          }
        });
    }
  }

  createAttribute(data: Pick<DeviceAttribute, 'name' | 'attributeType'>) {
    this.dataPointsFormArray.push(
      this.fb.group({
        name: [data.name],
        friendlyName: [''],
        attributeType: [data.attributeType],
        isChecked: [true],
      })
    );
    this.newAttributeFormComponent?.resetForm();
  }
}
