<mat-accordion class="add-datapoint-accordion">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon fontIcon="add" />
        Add new data point
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="newAttributeForm" #ngNewAttributeFormForm="ngForm" (ngSubmit)="createAttribute()">
      <div class="flex flex-row">
        <label class="input-main-label">Data point name:*</label>
        <div>
          <mat-form-field class="input-classic has-white-bg">
            <input formControlName="name" matInput placeholder="Enter data point name" type="text" />
            <mat-error
              *ngIf="
                newAttributeForm.get('name')?.hasError('required') || newAttributeForm.get('name')?.hasError('pattern')
              "
              >Input is not valid</mat-error
            >
            <mat-error *ngIf="newAttributeForm.get('name')?.hasError('alreadyExist')">Value already exist</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row">
        <label class="input-main-label">Attribute type:</label>
        <div>
          <mat-form-field class="input-classic">
            <input formControlName="type" matInput type="text" />
          </mat-form-field>
        </div>
      </div>

      <div class="flex justify-end">
        <button
          type="submit"
          class="test-submit-btn btn-green btn-medium font-bold"
          [disabled]="isNewAttributeCreating">
          <mat-icon fontIcon="add" />
          <mat-spinner *ngIf="isNewAttributeCreating" diameter="20" class="mr-1" />
          Add Point
        </button>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
