<app-page-header>
  <div class="mb-4 leading-10">My Profile</div>
</app-page-header>
<mat-card class="card pt-2">
  <mat-tab-group dynamicHeight mat-align-tabs="start" mat-stretch-tabs="false">
    <mat-tab label="Personal Info">
      <app-personal-profile *ngIf="user$ | async as user" [user]="user" />
    </mat-tab>
    <mat-tab label="Alert Settings" *ngIf="isSuperAdmin$ | async">
      <app-alert-settings />
    </mat-tab>
  </mat-tab-group>
</mat-card>
<div class="card-separator"></div>
