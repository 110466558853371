<div>
  <a
    [routerLink]="['/dashboard', 'device-management']"
    class="inline-flex items-center font-nunito text-ui-dark text-sm font-semibold">
    <mat-icon>chevron_left</mat-icon>
    Back to Devices
  </a>
  <app-page-header>Device Management</app-page-header>

  <mat-button-toggle-group [(ngModel)]="tab" class="main-toggle-group my-4">
    <mat-button-toggle class="border-ui-green-light" [value]="deviceTabs.MANUFACTURER">
      <mat-icon matIconPrefix *ngIf="tab === deviceTabs.MANUFACTURER">check</mat-icon>
      Manufacturer
    </mat-button-toggle>
    <mat-button-toggle class="border-ui-green-light" [value]="deviceTabs.DATA_POINTS">
      <mat-icon matIconPrefix *ngIf="tab === deviceTabs.DATA_POINTS">check</mat-icon>
      Data Points
    </mat-button-toggle>
    <mat-button-toggle class="border-ui-green-light" [value]="deviceTabs.MODEL">
      <mat-icon matIconPrefix *ngIf="tab === deviceTabs.MODEL">check</mat-icon>
      Model
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<app-manufacturer-list [hidden]="tab !== deviceTabs.MANUFACTURER" />

<app-data-points [hidden]="tab !== deviceTabs.DATA_POINTS" />

<app-model-list [hidden]="tab !== deviceTabs.MODEL" />
