import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesMainPageComponent } from './containers/devices-main-page/devices-main-page.component';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DeviceDetailsComponent } from './containers/device-details/device-details.component';
import { DeviceInfoBlockComponent } from './components/device-info-block/device-info-block.component';
import { MatCardModule } from '@angular/material/card';
import { DeviceLocationWidgetComponent } from './components/device-location-widget/device-location-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HighchartsChartComponent } from 'highcharts-angular';
import { AddDeviceComponent } from './containers/add-device/add-device.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { DeviceService } from '@services';
import { MatButtonModule } from '@angular/material/button';
import { DirectivesModule } from '@directives';
import { deviceResolver, deviceDetailsResolver, addDeviceFormResolver } from '@ngrx-store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeviceDetailsSkeletonAnimationComponent } from './components/device-details-skeleton-animation/device-details-skeleton-animation.component';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { EditDeviceComponent } from './containers/edit-device/edit-device.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { ExportReportMenuComponent } from '@standalone/export-report-menu/export-report-menu.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { DeviceStatsChartComponent } from '@standalone/device-stats-chart/device-stats-chart.component';
import { NewDeviceFormComponent } from '@standalone/new-device-form/new-device-form.component';
import { IncidentsTableDesktopFiltersComponent } from '@standalone/_tables/incidents-table-desktop-filters/incidents-table-desktop-filters.component';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';
import { DevicesTableDesktopFiltersComponent } from '@standalone/_tables/devices-table-desktop-filters/devices-table-desktop-filters.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { RoleGuard } from '@app-lib';
import { DeviceManagementComponent } from './containers/device-management/device-management.component';

@NgModule({
  declarations: [
    DevicesMainPageComponent,
    DeviceDetailsComponent,
    DeviceInfoBlockComponent,
    DeviceLocationWidgetComponent,
    AddDeviceComponent,
    DeviceDetailsSkeletonAnimationComponent,
    EditDeviceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: DevicesMainPageComponent,
        resolve: { deviceResolver },
      },
      {
        path: 'add-device',
        component: AddDeviceComponent,
        data: { breadcrumb: 'Add Device' },
        canActivate: [RoleGuard],
        resolve: { addDeviceFormResolver },
      },
      {
        path: 'device-management',
        component: DeviceManagementComponent,
        data: { breadcrumb: 'Device Management' },
        canActivate: [RoleGuard],
        resolve: { addDeviceFormResolver },
      },
      {
        path: 'edit/:locationId/:deviceId',
        component: EditDeviceComponent,
        data: { breadcrumb: 'Edit Device' },
        canActivate: [RoleGuard],
        resolve: { deviceDetailsResolver, addDeviceFormResolver },
      },
      {
        path: ':locationId/:deviceId',
        component: DeviceDetailsComponent,
        resolve: { deviceDetailsResolver },
      },
    ]),
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    DirectivesModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    PipesModule,
    NgxSkeletonLoaderModule,
    CdkCopyToClipboard,
    SkeletonTableComponent,
    SkeletonPageHeaderComponent,
    BreadcrumbsComponent,
    ImgLoaderComponent,
    IncidentStatusComponent,
    ExportReportMenuComponent,
    PageHeaderComponent,
    DeviceStatsChartComponent,
    NewDeviceFormComponent,
    IncidentsTableDesktopFiltersComponent,
    DevicesTableComponent,
    DevicesTableDesktopFiltersComponent,
    IncidentsTableComponent,
  ],
  providers: [HighchartsChartComponent, DeviceService],
})
export class DevicesModule {}
