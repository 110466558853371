import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { DeviceAttribute, DeviceAttributeType } from '@models';
import { attributeNameValidationPattern, removeSpacesValidator } from '@app-lib';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-new-attribute-form',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIcon,
    MatFormField,
    MatProgressSpinner,
    ReactiveFormsModule,
    MatError,
    MatSuffix,
    MatInput,
  ],
  templateUrl: './new-attribute-form.component.html',
})
export class NewAttributeFormComponent {
  @ViewChild('ngNewAttributeFormForm') ngNewAttributeFormForm: FormGroupDirective | undefined;
  @Input() realtimeAttributes: DeviceAttribute[] = [];
  @Input() isNewAttributeCreating = false;
  @Output() newAttributeEvent = new EventEmitter<Pick<DeviceAttribute, 'name' | 'attributeType'>>();

  newAttributeForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.newAttributeForm = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator, Validators.pattern(attributeNameValidationPattern)]],
      type: [{ value: DeviceAttributeType.REALTIME, disabled: true }],
    });
  }

  resetForm() {
    this.ngNewAttributeFormForm?.resetForm({
      name: '',
      type: DeviceAttributeType.REALTIME,
    });
  }

  createAttribute() {
    if (this.newAttributeForm.valid) {
      if (this.checkAttributeAlreadyExist()) {
        this.newAttributeForm.controls['name'].setErrors({ alreadyExist: true });
        return;
      }
      this.newAttributeEvent.emit(this.newAttributeForm.getRawValue());
    }
  }

  checkAttributeAlreadyExist() {
    return this.realtimeAttributes.some(
      ({ name }) => name.toLowerCase() === this.newAttributeForm.value.name.toLowerCase()
    );
  }
}
