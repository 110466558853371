import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { MatDialog } from '@angular/material/dialog';
import { openAddDeviceModelDialog } from '@standalone/_modals/add-device-model-dialog/add-device-model-dialog.component';
import { AppState, getDeviceManufacturers, getDeviceModels, getMyClient } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceModel, SelectOption, UserClient } from '@models';
import { Observable } from 'rxjs';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { DeviceHelperService } from '@services';

interface DeviceModelExtended extends DeviceModel {
  show: boolean;
  index: number;
}

@Component({
  selector: 'app-model-list',
  standalone: true,
  imports: [
    CommonModule,
    MatCard,
    MatCardTitle,
    MatIconModule,
    SearchInputComponent,
    ImgLoaderComponent,
    MultipleSelectComponent,
    CommonModule,
  ],
  templateUrl: './model-list.component.html',
})
export class ModelListComponent implements OnDestroy {
  modelSearch = '';
  deviceTypeValue: Array<string> = [];
  deviceTypeOptions: SelectOption[] = [];
  manufacturerValue: Array<string> = [];
  manufacturerOptions: SelectOption[] = [];
  deviceModels: DeviceModel[] = [];
  filteredDeviceModels: DeviceModelExtended[] = [];
  myClient$: Observable<UserClient | undefined>;

  modelSearchChange(search: string) {
    this.modelSearch = search;
    this.filterDeviceModels();
  }

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    public deviceHelperService: DeviceHelperService
  ) {
    this.myClient$ = this.store.select(getMyClient);
    this.store
      .select(getDeviceModels)
      .pipe(takeUntilDestroyed())
      .subscribe(models => {
        if (models.length) {
          this.deviceModels = models;
          this.deviceHelperService.initDeviceTypeOptions(models);
          this.filterDeviceModels();
        }
      });

    this.store
      .select(getDeviceManufacturers)
      .pipe(takeUntilDestroyed())
      .subscribe(manufacturers => {
        if (manufacturers.length) {
          this.manufacturerOptions = manufacturers.map(({ id, name }) => ({ title: name, value: id }));
        }
      });
  }

  ngOnDestroy() {
    this.deviceHelperService.resetState();
  }

  openAddDeviceModelDialog() {
    openAddDeviceModelDialog(this.dialog, {
      selectedDeviceType: '',
      selectedManufacturer: '',
    });
  }

  setDeviceType($event: Array<string>) {
    this.deviceTypeValue = $event;
    this.filterDeviceModels();
  }

  setManufacturer($event: Array<string>) {
    this.manufacturerValue = $event;
    this.filterDeviceModels();
  }

  filterDeviceModels() {
    let index = 0;
    this.filteredDeviceModels = this.deviceModels.map(model => {
      const nameMatch = !this.modelSearch || model.name.toLowerCase().includes(this.modelSearch.toLowerCase());
      const deviceTypeMatch = !this.deviceTypeValue.length || this.deviceTypeValue.includes(model.deviceType);
      const manufacturerMatch = !this.manufacturerValue.length || this.manufacturerValue.includes(model.make.id);
      const show = nameMatch && deviceTypeMatch && manufacturerMatch;

      if (show) {
        index = index + 1;
      }

      return { ...model, show, index };
    });
  }
}
