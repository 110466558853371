import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppSettingsActions,
  AppState,
  clientIdChanged,
  isSuperAdmin,
  loadChildClients,
  logout,
  selectChildClients,
  selectInProgressIncidentsAmount,
  selectNewIncidentsAmount,
} from '@ngrx-store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { ChildClient, MenuItemModel, UserStatus } from '@models';
import { AppService } from '@services';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { AdditionalMenuItems, MenuItems } from '@app/modules/main/components/main-menu/main-menu-items';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      state(
        'open',
        style({
          width: '160px',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          width: '0',
          opacity: 0,
        })
      ),
      transition('* => closed', [animate('.3s')]),
      transition('* => open', [animate('.3s')]),
    ]),
    trigger('inOutAnimationLogo', [
      state(
        'big',
        style({
          width: '200px',
          opacity: 1,
        })
      ),
      state(
        'small',
        style({
          width: '60px',
        })
      ),
      transition('* => small', [animate('.3s')]),
      transition('* => big', [animate('.3s')]),
    ]),
  ],
})
export class MainMenuComponent implements OnDestroy, OnInit {
  hide = true;
  showMenuSizeToggle = false;
  selectedClient: ChildClient | undefined;
  isSuperAdmin!: boolean;
  menuItems: MenuItemModel[] = MenuItems;
  additionalMenuItems: MenuItemModel[] = AdditionalMenuItems;
  logoutMenuItem: MenuItemModel = {
    itemIndex: 9,
    title: 'Log Out',
    icon: 'icon-log-out',
  };
  clientSearchValue = '';
  isSuperAdminSubs$: Subscription | undefined;
  clientListSubs$: Subscription | undefined;
  actionsSubs$: Subscription;
  clientsList: ChildClient[] = [];
  animationInProgress = false;
  newIncidentsAmount$: Observable<number | undefined>;
  inProgressIncidentsAmount$: Observable<number | undefined>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.autosizeMenu();
  }

  toggleMenu() {
    this.hide = !this.hide;
    localStorage.setItem('menuHide', JSON.stringify(this.hide));
    this.cdr.detectChanges();
  }

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private router: Router,
    actions$: Actions,
    private cdr: ChangeDetectorRef
  ) {
    const menuHide = localStorage.getItem('menuHide');
    if (menuHide) {
      this.hide = JSON.parse(menuHide);
    }

    this.actionsSubs$ = actions$.pipe(ofType(AppSettingsActions.clientIdChanged)).subscribe(() => {
      this.selectedClient = this.clientsList.find(({ id }) => id === this.appService.currentClient);
    });

    this.newIncidentsAmount$ = this.store.select(selectNewIncidentsAmount);
    this.inProgressIncidentsAmount$ = this.store.select(selectInProgressIncidentsAmount);
  }

  menuSettingInit() {
    this.isSuperAdminSubs$ = this.store.select(isSuperAdmin).subscribe(isSuperAdmin => {
      this.isSuperAdmin = isSuperAdmin;
      if (isSuperAdmin) {
        this.store.dispatch(loadChildClients());
      }
    });

    this.clientListSubs$ = this.store.select(selectChildClients).subscribe(clientList => {
      this.clientsList = clientList || [];
      if (clientList && clientList.length) {
        this.clientsList = this.clientsList.filter(item => item.status !== UserStatus.ARCHIVED);
        const currentClientId = this.appService.currentClient;
        const currentClient = clientList?.find(client => client.id === currentClientId);
        if (currentClient) {
          this.selectedClient = currentClient;
        } else {
          this.selectedClient = clientList[0];
          this.appService.currentClient = this.selectedClient.id;
        }
      }
    });
  }

  ngOnInit(): void {
    this.menuSettingInit();
    this.autosizeMenu();
  }

  autosizeMenu() {
    if (window.innerWidth < 700) {
      this.hide = false;
      this.showMenuSizeToggle = false;
    } else {
      this.showMenuSizeToggle = true;
    }
  }

  ngOnDestroy(): void {
    this.isSuperAdminSubs$?.unsubscribe();
    this.clientListSubs$?.unsubscribe();
    this.actionsSubs$.unsubscribe();
  }

  logout() {
    this.store.dispatch(logout());
  }

  sortByIndex(): MenuItemModel[] {
    return this.menuItems.sort((a, b) => (a.itemIndex > b.itemIndex ? 1 : a.itemIndex === b.itemIndex ? 0 : -1));
  }

  clientChange(client: ChildClient) {
    this.appService.currentClient = client.id;
    this.selectedClient = client;
    this.store.dispatch(clientIdChanged());
    this.resetSearch();
    this.router.navigateByUrl('');
  }

  resetSearch() {
    this.clientSearchValue = '';
  }

  clientSearch(value: string) {
    this.clientSearchValue = value;
  }
}
