@if((isCollectionLoading$ | async) === true) {
<ngx-skeleton-loader [theme]="{ height: '400px' }" />
} @else {
<mat-card class="card overflow-hidden h-full">
  <div class="h-full overflow-hidden flex flex-col">
    <div>
      <mat-card-title class="card-title-small mb-5">Manufacturer list</mat-card-title>
      <div class="border border-ui-gray-light border-b-0">
        <p
          class="bg-ui-gray-50 border-b border-b-ui-gray-light font-nunito text-sm text-ui-gray-100 font-semibold h-8 flex px-3 mb-0 items-center">
          <span class="flex items-center w-6 font-nunito text-xs text-ui-gray-100">№</span> Manufacturer
        </p>
        <form [formGroup]="form" (ngSubmit)="submit()" class="flex px-3 pt-5">
          <mat-form-field class="input-classic size-small w-full mr-3">
            <input class="w-full" formControlName="name" matInput placeholder="Enter manufacturer name" type="text" />
            <mat-error *ngIf="form.get('name')?.hasError('required')">Required</mat-error>
            <mat-error *ngIf="form.get('name')?.hasError('alreadyExist')">Name already exists</mat-error>
          </mat-form-field>
          @let isManufacturerCreating = (isManufacturerCreating$ | async);
          <button type="submit" [disabled]="isManufacturerCreating" class="btn-green btn-small min-w-[167px]">
            <mat-icon *ngIf="!isManufacturerCreating">add</mat-icon>
            <mat-spinner *ngIf="isManufacturerCreating" diameter="20" class="mr-1"></mat-spinner>
            Add Manufacturer
          </button>
        </form>
      </div>
    </div>
    <ul class="overflow-y-auto border border-ui-gray-light">
      @for (make of manufacturers; track make; let index = $index) {
      <li class="border-b border-b-ui-gray-light py-2 pr-2 pl-3 h-11 flex justify-start items-center">
        <span class="flex items-center w-6 font-nunito text-xs text-ui-gray-100">{{ index + 1 }}</span>
        <span class="font-nunito text-ui-dark text-sm">{{ make.name }}</span>
      </li>
      }
    </ul>
  </div>
</mat-card>
}
