<div class="flex justify-between items-center mb-4">
  <app-page-header><div class="leading-10">Devices</div></app-page-header>
  <div class="flex">
    <a appRestrictedEl class="btn-white-outlined btn-medium mr-3" routerLink="/devices/device-management">
      <i class="icon-settings text-3xl mr-2"></i>
      Device Management</a
    >
    <app-export-report-menu class="mr-3" (exportDataType)="exportDevices($event)" />
    <a appRestrictedEl class="btn-yellow btn-medium" routerLink="/devices/add-device">
      <mat-icon>add</mat-icon>
      Add Device</a
    >
  </div>
</div>

<mat-card class="card pt-0 pb-2">
  @let deviceListLoaded = deviceListLoaded$ | async; @if (deviceListLoaded ){
  <mat-card-content class="test-content p-0">
    <app-devices-table-desktop-filters tableId="device-main-table" />
    <app-devices-table tableId="device-main-table" [isNoDataBigSize]="true" [selectEnabled]="false" />
  </mat-card-content>
  } @else {
  <app-skeleton-table />
  }
</mat-card>
