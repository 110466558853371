<mat-card class="card mb-4">
  <mat-card-title class="card-title-small mb-4 flex items-center"
    ><mat-icon>add</mat-icon>Add new data point</mat-card-title
  >
  <form [formGroup]="form" #ngNewAttributeFormForm="ngForm" (ngSubmit)="submit()">
    <div class="flex flex-row">
      <label for="friendlyName" class="input-main-label">Friendly name:</label>
      <div>
        <mat-form-field class="input-classic">
          <input
            matInput
            id="friendlyName"
            type="text"
            placeholder="Enter data point friendly name"
            formControlName="friendlyName" />
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-row">
      <label for="name" class="input-main-label">Data point name: *</label>
      <div>
        <mat-form-field class="input-classic">
          <input matInput id="name" type="text" placeholder="Enter data point name" formControlName="name" />
          <mat-error *ngIf="form.get('name')?.hasError('required')">Input is not valid</mat-error>
          <mat-error *ngIf="form.get('name')?.hasError('alreadyExist')">Value already exist</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-row">
      <label for="type" class="input-main-label">Attribute type:</label>
      <div class="mr-5">
        <mat-form-field class="input-classic">
          <input id="type" formControlName="type" matInput type="text" />
        </mat-form-field>
      </div>
      @let isNewAttributeCreating = (isNewAttributeCreating$ | async);
      <button type="submit" class="test-submit-btn btn-green btn-medium" [disabled]="isNewAttributeCreating">
        <mat-icon *ngIf="!isNewAttributeCreating" fontIcon="add" />
        <mat-spinner *ngIf="isNewAttributeCreating" diameter="20" class="mr-1" />
        Add Point
      </button>
    </div>
  </form>
</mat-card>

<mat-card class="card">
  <mat-card-title class="card-title-small mb-4">Data Point list</mat-card-title>
  <div class="border border-ui-gray-light">
    <div class="flex items-center bg-ui-gray-50 font-nunito text-sm text-ui-gray-100 font-semibold h-8">
      <div class="flex px-3 items-center w-[33%]">
        <span class="flex items-center w-6 font-nunito text-xs text-ui-gray-100">№</span>Friendly Data Point Name
      </div>
      <div class="flex px-3 items-center w-[29%]">Data Point Name</div>
      <div class="flex px-3 items-center w-[38%]">Attribute type</div>
    </div>
    <ul class="max-h-[440px] overflow-y-auto">
      @for (attribute of realtimeAttributes; track attribute; let index = $index) {
      <li class="border-t border-t-ui-gray-light py-2 h-11 flex items-center font-nunito text-sm text-ui-dark">
        <div class="flex px-3 w-[33%] items-center">
          <span class="flex items-center w-6 font-nunito text-xs text-ui-gray-100">{{ index + 1 }}</span
          >{{ attribute.friendlyName }}
        </div>
        <div class="flex px-3 w-[29%] items-center">
          {{ attribute.name }}
        </div>
        <div class="flex px-3 w-[38%] items-center">
          {{ attribute.attributeType }}
        </div>
      </li>
      }
    </ul>
  </div>
</mat-card>
