<div *ngIf="locationsLoaded$ | async; else loading" class="test-dashboard-main">
  <div class="flex justify-between items-center mb-4">
    <app-page-header><div class="leading-10">Dashboard</div></app-page-header>
    <div class="flex items-center">
      <div class="flex mb-1">
        <mat-slide-toggle
          class="main-toggle mr-4"
          labelPosition="before"
          [checked]="mapEnabled"
          (change)="toggleMap($event)">
          View Map
        </mat-slide-toggle>
      </div>
      <a appRestrictedEl class="btn-green btn-medium" routerLink="add-new-site">
        <mat-icon fontIcon="add" />
        Add New Building</a
      >
    </div>
  </div>
  <!-- <app-site-widget></app-site-widget> -->
  <div class="test-map-container overflow-hidden rounded-lg" [class]="!mapEnabled ? 'invisible h-0' : 'mb-4 '">
    <div class="relative h-[400px]">
      <app-multiple-select
        #alertSelectRef
        [options]="mapFilterService.mapFilterOptions"
        label="Map Alerts"
        panelClass="multiselect-map-filter-overlay"
        selectClass="stroked-select-multiple mb-0 map-site-filter"
        [showSelectedValues]="false"
        [sortingEnabled]="false"
        (selectDataEvent)="mapFilterChange($event)" />
      <app-dashboard-map />
    </div>
  </div>

  <mat-card class="card pb-2">
    <mat-card-title class="card-title">All Buildings</mat-card-title>
    <mat-card-content class="p-0">
      <app-site-table-desktop-filters />
      <app-site-table tableId="dashboard-site-table" [isInjected]="true" />
    </mat-card-content>
  </mat-card>
  <div class="card-separator"></div>
</div>

<ng-template #loading>
  <div class="test-skeleton-animation">
    <app-skeleton-page-header [buttonCount]="1" />
    <ngx-skeleton-loader [theme]="{ height: '400px' }" />
    <app-skeleton-table />
  </div>
</ng-template>
