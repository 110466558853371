import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { DeviceAttribute, DeviceAttributeType, UserClient } from '@models';
import {
  addNewAttribute,
  AppState,
  DevicesActions,
  getAttributes,
  getMyClient,
  isNewAttributeCreating,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatError, MatFormField } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { attributeNameValidationPattern, removeSpacesValidator } from '@app-lib';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-data-points',
  standalone: true,
  imports: [
    CommonModule,
    MatCard,
    MatCardTitle,
    MatIcon,
    MatFormField,
    MatError,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinner,
  ],
  templateUrl: './data-points.component.html',
})
export class DataPointsComponent {
  @ViewChild('ngNewAttributeFormForm') ngNewAttributeFormForm: FormGroupDirective | undefined;
  realtimeAttributes: DeviceAttribute[] = [];
  form: FormGroup;
  client: UserClient | undefined;
  isNewAttributeCreating$: Observable<boolean | undefined>;

  constructor(private store: Store<AppState>, private fb: FormBuilder, private actions$: Actions) {
    this.store
      .select(getAttributes)
      .pipe(takeUntilDestroyed())
      .subscribe(attributes => {
        if (attributes.length) {
          this.realtimeAttributes = attributes.filter(
            attribute => attribute.attributeType === DeviceAttributeType.REALTIME
          );
        }
      });

    this.store
      .select(getMyClient)
      .pipe(takeUntilDestroyed())
      .subscribe(client => (this.client = client));

    actions$.pipe(ofType(DevicesActions.addNewAttributeSuccess), takeUntilDestroyed()).subscribe(() => {
      this.ngNewAttributeFormForm?.resetForm({
        name: '',
        friendlyName: '',
        type: DeviceAttributeType.REALTIME,
      });
    });

    this.isNewAttributeCreating$ = this.store.select(isNewAttributeCreating);

    this.form = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator, Validators.pattern(attributeNameValidationPattern)]],
      friendlyName: ['', removeSpacesValidator],
      type: [{ value: DeviceAttributeType.REALTIME, disabled: true }],
    });
  }

  submit() {
    if (this.form.valid && this.client) {
      if (this.checkAttributeAlreadyExist()) {
        this.form.controls['name'].setErrors({ alreadyExist: true });
        return;
      }
      console.log('submit', this.form.getRawValue());
      // this.store.dispatch(addNewAttribute({ clientId: this.client.id, data: this.form.getRawValue() }));
    }
  }

  checkAttributeAlreadyExist() {
    return this.realtimeAttributes.some(({ name }) => name.toLowerCase() === this.form.value.name.toLowerCase());
  }
}
