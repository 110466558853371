import { Component, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DeviceManufacturer, UserClient } from '@models';
import { Store } from '@ngrx/store';
import {
  addNewManufacturer,
  AppState,
  DevicesActions,
  getDeviceManufacturers,
  getMyClient,
  isCollectionCreating,
  isManufacturerCreating,
} from '@ngrx-store';
import { Actions, ofType } from '@ngrx/effects';
import { capitalize, removeSpacesValidator } from '@app-lib';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-manufacturer-list',
  standalone: true,
  imports: [
    CommonModule,
    MatCard,
    MatCardTitle,
    NgxSkeletonLoaderModule,
    MatProgressSpinner,
    MatIcon,
    MatError,
    MatFormField,
    ReactiveFormsModule,
    NgIf,
    MatInputModule,
  ],
  templateUrl: './manufacturer-list.component.html',
})
export class ManufacturerListComponent {
  form: FormGroup;
  isManufacturerCreating$: Observable<boolean | undefined>;
  isCollectionLoading$: Observable<boolean | undefined>;
  client: UserClient | undefined;
  manufacturers: DeviceManufacturer[] = [];
  @ViewChild('ngForm') ngForm: FormGroupDirective | undefined;

  constructor(private fb: FormBuilder, private store: Store<AppState>, actions$: Actions) {
    this.form = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator]],
    });

    this.isManufacturerCreating$ = this.store.select(isManufacturerCreating);
    this.isCollectionLoading$ = this.store.select(isCollectionCreating);

    actions$.pipe(ofType(DevicesActions.addNewManufacturerSuccess), takeUntilDestroyed()).subscribe(() => {
      this.ngForm?.resetForm();
    });
    this.store
      .select(getMyClient)
      .pipe(takeUntilDestroyed())
      .subscribe(client => (this.client = client));

    this.store
      .select(getDeviceManufacturers)
      .pipe(takeUntilDestroyed())
      .subscribe(manufacturers => {
        if (manufacturers.length) {
          this.manufacturers = manufacturers;
        }
      });
  }

  submit() {
    const value = this.form.value.name.trim();
    if (this.form.valid && this.client) {
      if (this.checkManufacturerAlreadyExist()) {
        this.form.controls['name'].setErrors({ alreadyExist: true });
        return;
      }
      this.store.dispatch(
        addNewManufacturer({
          clientId: this.client.id,
          newManufacturerData: { name: capitalize(value) },
        })
      );
    } else {
      this.form.markAsTouched();
    }
  }

  checkManufacturerAlreadyExist() {
    return this.manufacturers.some(({ name }) => name.toLowerCase() === this.form.value.name.trim().toLowerCase());
  }
}
